@import url('https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap');
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css');

body {
  font-family: 'Raleway';
}

h6 {
  letter-spacing: 10px;
  font-weight: 400;
}

a {
  color: gray;
}

.fa-instagram:hover {
  cursor: pointer;
  color: black;
  transition: all 1s ease-in-out;
}